/**
 * @copyright 2019 @ DigiNet
 * @author tranghoang
 * @create 2019/01/24 09:15
 * @update 2019/01/24 09:15
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SearchToolBar from "./tlb-search";
import ButtonBasic from "../common/button/button-basic";
import DropdownToolbar from "../grid-container/template/dropdown-toolbar";
import Config from "../../config";
import {CheckBox} from "devextreme-react";
import ButtonGeneral from "../common/button/button-general";

class GridToolbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            totalItems: 0,
            itemPerPage: 20,
            curPerPage: 20,
            currentPage: 0,
            listPerPage: [10, 20, 30, 50],
        };
    }

    onButtonClick(allowAdding) {
        if (typeof allowAdding == 'undefined') {
            if (this.props.onAddNew)
                this.props.onAddNew();
        } else {
            if (this.props.onAddNew && allowAdding >= 4)
                this.props.onAddNew();
        }
    }

    onSendMailClick() {
        if (this.props.onSendMail) {
            this.props.onSendMail();
        }
    }

    renderButton = (data, index) => {
        let buttonRender;
        switch (data.buttonType) {
            case 'dropdown':
                buttonRender =
                    <DropdownToolbar key={`buttonGrid${index}`} disabled={data.disabled} menuItem={data.menuItem}
                                     icon={data.icon} buttonName={data.buttonName}/>;
                break;
            case 'label':
                buttonRender = <label key={`buttonGrid${index}`}
                                      className={`${data.className ? data.className : ''} control-label pdl10`}>
                    <i className={`${data.icon ? `${data.icon} mgr5` : ''} `}/>{data.buttonName && data.buttonName}<i>{data.dataFieldLb}</i></label>;
                break;
            case 'check':
                buttonRender =
                    <CheckBox key={`buttonGrid${index}`} className={`${data.className ? data.className : ''} mgt5`}
                              width={200} text={data.buttonName && data.buttonName} id={"Disabled"}
                              onValueChanged={data.onValueChanged && data.onValueChanged}
                    />;
                break;
            default:
                buttonRender = <ButtonBasic key={`buttonGrid${index}`} disabled={data.disabled}
                                            className={`${data.className ? data.className : ''}  btn-crm`}
                                            onClick={data.onClick && data.onClick}>
                    {data.icon &&
                    <i className={data.icon && data.icon}> &nbsp; </i>}{data.buttonName && data.buttonName}
                </ButtonBasic>;
                break;
        }
        return buttonRender
    };


    render() {
        const {buttonCRM, onAddNew, allowAdding, isPer, showSendMail, allowSearch, onSearch, valueSearch} = this.props;

        return (
            <div className="toolbar-container">
                <div className={'Lbutton-Toolbar'}>
                    {buttonCRM && buttonCRM.map((value, index) => {
                        return value.align !== 'right' ? this.renderButton(value, index) : null
                    })}
                </div>
                {onAddNew &&
                <ButtonGeneral typeButton={'add'} onClick={() => this.onButtonClick(allowAdding)}
                           name={Config.lang("DHR_Them_moi")}
                           className={allowAdding <= 1 ? 'isDisabled' : ''}
                           disabled={isPer < 2}/>
                }

                {showSendMail &&
                <ButtonGeneral typeButton={'send'} onClick={() => this.onSendMailClick()} name={Config.lang("DHR_Gui_mail")}/>
                }
                <SearchToolBar
                    valueSearch={valueSearch}
                    allowSearch={allowSearch}
                    onSearch={(text) => {
                        onSearch && onSearch(text)
                    }}/>
                <div className={'Rbutton-Toolbar'}>
                    {buttonCRM && buttonCRM.map((value, index) => {
                        return value.align === 'right' ? this.renderButton(value, index) : null
                    })}
                </div>
            </div>
        );
    }
}

GridToolbar.propTypes = {
    totalItems: PropTypes.number,
    itemPerPage: PropTypes.number,
    currentPage: PropTypes.number,
    curPerPage: PropTypes.number,
    listPerPage: PropTypes.array,
    showSendMail: PropTypes.bool,
    buttonCRM: PropTypes.array,
    isPer: PropTypes.number,
    allowAdding: PropTypes.number,
    valueSearch: PropTypes.string,

    onSearch: PropTypes.func,
    onChangePage: PropTypes.func,
    onChangePerPage: PropTypes.func,
    onSendMail: PropTypes.func,
    onAddNew: PropTypes.func,
};

export default GridToolbar;
