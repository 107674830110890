/**
 * @copyright 2019 @ DigiNet
 * @author tranghoang
 * @create 2019/08/05 18:12
 * @update 2019/08/05 18:12
 */

module.exports = {
    api: process && process.env && process.env.REACT_APP_API ? process.env.REACT_APP_API : 'https://apricot.diginet.com.vn/dhr-api-dev/api',
    url: process && process.env && process.env.REACT_APP_WEB ? process.env.REACT_APP_WEB : 'https://apricot.diginet.com.vn/dhr-dev',
    secret : process && process.env && process.env.REACT_APP_SECRET ? process.env.REACT_APP_SECRET :  'd9hc5d0k5LnJx9Cvk9NwOe3uGy0cW9ja2kv3dg',

    cdn: process && process.env && process.env.REACT_APP_CDN_URL ? process.env.REACT_APP_CDN_URL : '',
    secretCDN : process && process.env && process.env.REACT_APP_CDN_SECRET ? process.env.REACT_APP_CDN_SECRET :  '',

    prefixLang : process && process.env && process.env.REACT_APP_PREFIXLANG ? process.env.REACT_APP_PREFIXLANG :  'DHR_',
    companyCode : process && process.env && process.env.REACT_APP_COMPANYCODE ? process.env.REACT_APP_COMPANYCODE :  'APRICOT',
    productID : process && process.env && process.env.REACT_APP_PRODUCTID ? process.env.REACT_APP_PRODUCTID :  'WHRP',

    mookAPI: process && process.env && process.env.REACT_APP_MOOK_API ? process.env.REACT_APP_MOOK_API :  'NO',
    keyEnv: process && process.env && process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV :  'NO',
};
