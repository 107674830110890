/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 11/12/2019
 * @Example 
 */

import React, {PureComponent} from 'react';
import PropTypes from "prop-types";
import Config from "../../config";
import CloseIcon from '@material-ui/icons/Close';

import {
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import {Column} from "devextreme-react/data-grid";
import {Col, FormGroup, Row} from "react-bootstrap";
import ButtonGeneral from "../common/button/button-general";
import GridContainer from "../grid-container/grid-container";

const styles = {
    paper: {
        alignSelf: 'start',
        overflowX: 'hidden'
    },
};

class PopupSelect extends PureComponent {
    constructor(props){
        super(props);
        this.state = {
            isOpen: false,
            selectedRowKeys: []
        };
        this.tmpSelectRowKeys = [];
    }

    _onInit = () => {
        const {onInit} = this.props;
        const {isOpen} = this.state;
        if (isOpen) {
            if (onInit) onInit();
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.open !== this.props.open) {
            this.setState({
                isOpen: this.props.open
            });
        }
        if (JSON.stringify(prevProps.value) !== JSON.stringify(this.props.value)) {
            this.tmpSelectRowKeys = this.props.value
        }
        this._onInit();
    }

    open = (data) => {
        const {mode, onOpen} = this.props;
        if (data) {
            data = !Array.isArray(data) ? [data] : data;
            data = mode === 'single' ? [data[0]] : data;
        }
        this.tmpSelectRowKeys = data;
        this.setState({
            isOpen: true,
        }, () => {
            if (onOpen) onOpen();
        });
    };

    _onEntered = () => {
        setTimeout(() => {
            this.setState({
                selectedRowKeys: this.tmpSelectRowKeys
            });
        }, 300);
    };

    close = (e) => {
        const {onClose} = this.props;
        this.setState({
            isOpen: false
        }, () => {
            if (onClose) onClose(e);
        });
    };

    onSave = () => {
        const {onSave} = this.props;
        const {selectedRowKeys} = this.state;
        if (onSave) onSave(selectedRowKeys);
        this.close();
    };

    onSelectionChanged = (e) => {
        const {mode} = this.props;
        this.setState({
            selectedRowKeys: mode === 'single' ? e.currentSelectedRowKeys : e.selectedRowKeys
        });
    };

    _mapDataSource = (dataSource, selectedRowKeys) => {
        const {keyExpr, allowFilterSelected} = this.props;
        return dataSource.map(d => {
            d.sortOrder = allowFilterSelected && selectedRowKeys.includes(d[keyExpr]) ? 1 : 0;
            return d;
        });
    };

    render() {
        let {dataSource, column, itemPerPage, skipPerPage, listPerPage, typePaging, title, component, children, className, style, fullWidth, maxWidth, mode,
                keyExpr, transition, onOpen, onClose, allowFilterSelected, ...props} = this.props;
        const {isOpen, selectedRowKeys} = this.state;
        const popupTransitions = Config.helpers.popupTransitions;
        const Trans = transition && popupTransitions[transition] ? popupTransitions[transition] : Config.popupTransition;
        if (props.onSave) delete props.onSave;
        if (props.allowFilterSelected) delete props.allowFilterSelected;
        dataSource = this._mapDataSource(dataSource, selectedRowKeys);

        return (
            <React.Fragment>
                <Dialog
                    {...props}
                    open={isOpen}
                    className={className}
                    style={style}
                    fullWidth={fullWidth}
                    maxWidth={maxWidth ? maxWidth : 'md'}
                    PaperProps={{
                        style: styles.paper,
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    onEntered={this._onEntered}
                    onClose={this.close}
                    TransitionComponent={Trans}
                >
                    <DialogTitle disableTypography>
                        <Typography variant="h6">{title ? title : Config.lang("DHR_Chon")}</Typography>
                        <IconButton aria-label="close" style={styles.closeButton} onClick={this.close}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogActions style={{borderTop: "1px solid #ddd"}}>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <ButtonGeneral name={Config.lang('DHR_Dong1')}
                                               typeButton={'close'}
                                               className={'pull-right'}
                                               onClick={this.close}
                                               size={'medium'}
                                />
                                <ButtonGeneral name={Config.lang('DHR_Luu')}
                                               typeButton={'save'}
                                               size={'medium'}
                                               className={'pull-right mgr5'}
                                               onClick={this.onSave}/>
                            </Col>
                        </Row>
                    </DialogActions>
                    <DialogContent dividers style={{height: "100%"}}>
                        <FormGroup>
                            <GridContainer
                                itemPerPage={itemPerPage || 30}
                                skipPerPage={skipPerPage || 0}
                                listPerPage={listPerPage || [30, 60, 100]}
                                height={() => {
                                    return window.innerHeight - 240;
                                }}
                                pagerFullScreen={false}
                                repaintChangesOnly={true}
                                allowColumnReordering={true}
                                highlightChanges={true}
                                keyExpr={keyExpr}
                                dataSource={dataSource}
                                columnAutoWidth={true}
                                rowAlternationEnabled={true}
                                filterRow={{
                                    visible: true
                                }}
                                selectedRowKey={selectedRowKeys}
                                selection={{
                                    allowSelectAll: mode === 'multiple',
                                    mode: "multiple",
                                    selectAllMode: "allPages",
                                    showCheckBoxesMode: "always"
                                }}
                                onSelectionChanged={this.onSelectionChanged}
                                typePaging={typePaging || "remote"}
                                showRowLines={true}
                                showBorders={false}
                                showColumnLines={false}
                                noDataText={Config.lang("DHR_No_data")}
                            >
                                {column.map((e) => {
                                    return (
                                        <Column
                                            {...e}
                                            key={e.dataField}
                                            caption={e.caption}
                                            dataField={e.dataField}
                                        />
                                    );
                                })}
                                <Column
                                    dataField={"sortOrder"}
                                    visible={false}
                                    sortOrder={"desc"}
                                />
                            </GridContainer>
                        </FormGroup>
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        );
    }
}

PopupSelect.propTypes = {
    dataSource: PropTypes.any,
    column: PropTypes.any,
    keyExpr: PropTypes.string,
    open: PropTypes.bool,
    value: PropTypes.any,
    itemPerPage: PropTypes.number,
    mode: PropTypes.string, //'single', 'multiple'
    className: PropTypes.string,
    style: PropTypes.string,
    title: PropTypes.string,
    fullWidth: PropTypes.bool,
    maxWidth: PropTypes.string,
    allowFilterSelected: PropTypes.bool,
    listPerPage: PropTypes.array,
    skipPerPage: PropTypes.number,
    /**
     * @typePaging: String
     * "default": With default type of DataGrid Devextreme
     * "normal": With type of HR customize paging (local).
     * "remote": With type of HR customize paging (remote). Require props:
     *      - @totalItems: Total of all data.
     */
    typePaging: PropTypes.oneOf(['default', 'normal', 'remote']),

    onClose: PropTypes.func,
    onSave: PropTypes.func,
};

export default PopupSelect;
