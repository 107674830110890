/**
 * @copyright 2019 @ DigiNet
 * @author tranghoang
 * @create 2019/04/24 18:12
 * @update 2019/04/24 18:12
 */

import {locale, loadMessages} from 'devextreme/localization';
import * as Helpers from '../components/common/helpers';
import {DataSource} from "devextreme/data/data_source/data_source";
import {isMobile} from "react-device-detect";
import {browserHistory} from "react-router";
import * as CryptoJS from "crypto-js";
import moment from "moment";
import _ from "lodash";
import db from "../components/libs/dexie";
const env = require('./environment');
const crypto = require('crypto');

class Config {

//////////////////////////
    //Use localStorage..
    static getKeyEnv = (key = "") => {
        const prefixLang = Config?.env?.prefixLang || "";
        let keyEnv = Config.env.keyEnv && Config.env.keyEnv !== "NO" ? Config.env.keyEnv : "";
        keyEnv = keyEnv.trim() + prefixLang.trim();
        return keyEnv.trim() + key;
    };
    static setLocalStorage = (key, value) => {
        if (!key) return false;
        key = Config?.getKeyEnv(key);
        try {
            localStorage.setItem(key, value);
        } catch (e) {
            return false;
        }
    };
    static getLocalStorage = (key, isJSONParse = false, isNewKey = false) => {
        if (!key) return null;
        const newKey = Config?.getKeyEnv(key);
        try {
            let item = localStorage.getItem(newKey);
            if (!isNewKey && !item) {
                item = localStorage.getItem(key);
            }
            if (this.isJson(item) && isJSONParse) item = JSON.parse(item);
            return item;
        } catch (e) {
            return null;
        }
    };
    static removeLocalStorage = (keys) => {
        if (!keys || keys.length <= 0) return false;
        const keyEnv = Config?.getKeyEnv();
        try {
            if (Array.isArray(keys)) {
                for (let key of keys) {
                    const k = keyEnv ? keyEnv.trim() + key : key;
                    localStorage.removeItem(k);
                }
            } else {
                keys = keyEnv ? keyEnv.trim() + keys : keys;
                localStorage.removeItem(keys);
            }
            return true;
        } catch (e) {
            return false;
        }
    };
    //Use localStorage..
    ////////////////////////////////////////

    static env = env;
    static token = {};
    static tokenCDN = {};
    static isMobile = isMobile;
    static color = {};
    static themes = [];
    static profile = {};
    static setting = [];
    static cdn = {
        URL: env.cdn,
        secret: env.secretCDN,
        token: {}
    };
    static getToken = false;
    static filters = null;
    static formInfo = null;
    static formID = "";
    static popup = null;
    static popup2 = null;
    static notify = null;
    static notifyError = null;
    static viewType = 0;
    static language = null;
    static perPage = 20;
    static database = '';
    static helpers = Helpers;
    static controller = null;
    static popupTransition = Helpers.popupTransitions.Slide;
    static permission = {
        'USER': 1,
        'ADMIN': 2,
        'SUPPORTER': 3,
        'BOT': 4
    };
    static currentMenu = null;
    static menuType = Number(Config.getLocalStorage("MENUTYPEDHR")); //default MSS...
    static localization = null;
    static URlDef = require('../assets/images/icon-user-default.png');
    static listUsers = [];
    static encryptKey = "DHR@012345678#keyEncrypt";
    static coreTheme = null;
    static deployer = process?.env?.REACT_APP_PASSWORD_DEPLOYER || '@12345@';

    static getHREmployeeID = () => {
        let result = '';
        if (this.profile.HREmployeeID) {
            result = this.profile.HREmployeeID;
        }
        return result
    };

    static getDivisionID = () => {
        let result = '';
        if (this.profile.DivisionID) {
            result = this.profile.DivisionID;
        }
        return result;
    };

    static getHRTransMonth = () => {
        let result = '';
        if (this.profile.TranMonth) {
            result = this.profile.TranMonth;
        }
        return result;
    };

    static getHRTransYear = () => {
        let result = '';
        if (this.profile.TranYear) {
            result = this.profile.TranYear;
        }
        return result;
    };

    static getCreatorHR = () => {
        let result = '';
        if (this.profile.CreatorHR) {
            result = this.profile.CreatorHR;
        }
        return result
    };

    static getDatabaseName = () => {
        return Config.getSetting('DB_APP_NAME') ? Config.getSetting('DB_APP_NAME') : "";
    };

    /**
     * Get height of grid with 100% - [heigth of header] - [height of toolbar] - [height of paging]
     * @param parentHeight: number
     * @returns {number}
     */
    static getHeightGrid = (parentHeight) => {
        const _toolbar = document.getElementById("action-toolbar");
        const _header = document.querySelector(".header-container");
        const _pHeight = parentHeight || window.innerHeight;
        const headerHeight = _header ? _header.offsetHeight : 50;
        const toolbarHeight = _toolbar ? _toolbar.offsetHeight : 50;
        return _pHeight - (headerHeight + toolbarHeight + 15); //15: padding bottom of content-container
    };

    static getLocale = () => {
        let result = "vi";
        if (Config.getLocalStorage('langDHR')) {
            result = Config.getLocalStorage('langDHR');
        }
        return result;
    };

    /**
     * Get system setting
     * Example:  getSetting('DHR_NAME') => 'DRD02V41'
     * @param name
     * @param dataSetting
     * @param isEncrypt
     * @param returnFull
     * @returns {any|Array|{rows}|null|*|number}
     */
    static getSetting = (name, dataSetting, isEncrypt = false, returnFull = false) => {
        dataSetting = dataSetting ? dataSetting : Config.setting;
        dataSetting = isEncrypt ? JSON.parse(this.decryptCrypto(dataSetting)) : dataSetting;
        if (dataSetting) {
            const settings = dataSetting && dataSetting.rows ? dataSetting.rows : dataSetting;
            if (settings) {
                if (!name) {
                    if (returnFull) {
                        return dataSetting;
                    } else {
                        return settings;
                    }
                } else {
                    let setting = settings.find(s => s.name === name);

                    if (setting && setting.name) {
                        switch (setting.type) {
                            case "NUMBER":
                                return parseFloat(setting.value);
                            case "JSON":
                                return JSON.parse(setting.value);
                            default:
                                return setting.value;
                        }
                    }
                }
            } else {
                console.log("decrypt setting error");
            }
        }

        return null;
    };

    static logout = (route = "") => {
        Config.controller.deleteDevice(() => {
            Config.removeLocalStorage('TOKENDHR');
            Config.removeLocalStorage('PROFILEDHR');
            Config.removeLocalStorage('SETTINGDHR');
            Config.removeLocalStorage('FORMINFODHR');
            Config.removeLocalStorage('MENUTYPEDHR');
            // Config.removeLocalStorage('langDHR');
            // Config.removeLocalStorage('breadcrumbHR');
            Config.removeLocalStorage('tokenCDN');
            if (db && db.userCache) {
                db.userCache.clear().then(() => {
                    window.location.href = Config.getRootPath() + route;
                });
            } else {
                window.location.href = Config.getRootPath() + route;
            }
        });
    };

    /**
     * Replace special symbol,using for control as textbox Code ,ID .v.v.
     * Ex: replaceSymbol('*Khoái') => 'Khoai'
     * @param value
     * @returns {string}
     */
    static replaceSymbol = (value) => {
        // let str = str.replace(/[|?~=",{}[\];^%']/gi, '');
        let str = value.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
        str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
        str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
        str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
        str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
        str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
        str = str.replace(/đ/g, "d");
        str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
        str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
        str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
        str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
        str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
        str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
        str = str.replace(/Đ/g, "D");
        str = str.replace(/\s/g, '');
        str = str.replace(/[^0-9a-z_\-#*/(\\)]/gi, '');

        return str.toUpperCase();
    };

    /**
     * Convert date by special format
     * @param value
     * @param defaultValue
     * @param format
     * @param isUTC
     * @param inputFormat
     * @returns {string}
     */

    static convertDate = (value, defaultValue, format = "DD/MM/YYYY", isUTC = true, inputFormat) => {
        if (!value || !moment(value).isValid()) return defaultValue ? defaultValue : null;
        if (isUTC) {
            return moment.utc(value, inputFormat).format(format)
        } else {
            return moment(value,inputFormat).format(format);
        }
    };

    /**
     * Using translate resource of project (caption,title,description,label,text.v.v.)
     * Example : lang(Dang_nhap) => vi:Đăng nhập  || en :Log In
     * @param text
     * @param mode
     * @returns {*}
     */
    static getKeyLocalize = (text) => {
        if (!Config.localization || !text) return "";
        const prefix = this.env.prefixLang || "ERP_";
        if (text && text.includes(prefix)) text = text.replace(prefix, '');
        return Object.keys(Config.localization).find(l => {
            if (l && l.includes(prefix)) l = l.replace(prefix, '');
            return l.toLowerCase() === text.toLowerCase();
        });
    };
    static lang = (text, mode) => {
        let str;
        if (Config.localization !== null) {
            let keyTmp = text;
            if (text && text.match(/%(.*?)%/g)) {
                keyTmp = text.replace(/%(.*?)%/g, "%p"); //define key param, require is %p%.
            }
            let key = this.getKeyLocalize(keyTmp);
            if(mode){
                const lc =Config.getLocalStorage('LOCALIZE');
                const lang = JSON.parse(lc);
                str = lang[mode][key];

            } else {
                // str = Config.localization[text] ? Config.localization[text] : Config.localization[text1];
                str = Config.localization[key];
            }
            if (typeof str !== "undefined") {
                const match = text.match(/%(.*?)%/i);
                const match2 = text.match(/%(.*?)%/g);
                if (match) {
                    match2.forEach(m => {
                        m = m.slice(1, -1);
                        str = str.replace("%p", m);
                    });
                }
                return str;
            }
        }
        return text;
    };

    /**
     * Set language devextreme component
     * @returns {void}
     */
    static setLangDevextreme() {
        const lang = Config.getLocalStorage('langDHR');
        let dataLang = Config.getLocalStorage('LOADDEVEXTREME');
        if(dataLang && this.isJson(dataLang)){
            dataLang = JSON.parse(dataLang);
            loadMessages(dataLang);
            locale(lang);
        }
        if (lang === 'vi' && window.dxMessage) {
            Config.setLocalStorage('LOADDEVEXTREME', JSON.stringify(window.dxMessage));
            loadMessages(window.dxMessage);
            locale('vi');
        }
        else if(lang === 'en'){
            locale('en');
        }
    }

    static randomSRC (width, height){
        const widthImage = width ? width : 320;
        const heightImage = height ? height : 320;
        return "https://loremflickr.com/"+widthImage+"/"+heightImage;
    };

    static getRootPath() {
        let profile = Config.decryptData(Config.getLocalStorage('PROFILEDHR'));
        profile = profile ? JSON.parse(profile) : null;
        let url = (process && process.env && process.env.REACT_APP_ROOT) ? process.env.REACT_APP_ROOT : '/';
        if(!profile) return url;
        const isHcs = profile.IsHcsPermission && window.location.href.includes('hcs');
        const isPortal = profile.IsPortalPermission && window.location.href.includes('portal');
        const errHcs = profile.IsHcsPermission && !window.location.href.includes('hcs');
        const errPortal = profile.IsPortalPermission && !window.location.href.includes('portal');
        if(isHcs){
            url += 'hcs/';
        }
        else if(isPortal){
            url += 'portal/';
        }
        else if(errHcs || errPortal){
            url += errHcs ? 'hcs/' : 'portal/';
            window.location.href=url;
        }
        return url;
    }

    static getCDNPath(allowSlash = true) {
        return this.cdn.URL + (allowSlash ? "/" : "");
    }

    static isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    static storeDataSoureDevExtreme(dataSource, limit) {
        if(dataSource && dataSource.rows) dataSource=dataSource.rows;
        if (!dataSource || dataSource.length <= 0) return [];
        return new DataSource({
            store: dataSource,
            paginate: true,
            pageSize: limit ? limit : 10
        });
    }

    /**
     * Save state to location with browserHistory.
     * @param component
     * @param param = {}
     */
    static setStoreState(component, param) {
        const route = component.props.route.path;

        browserHistory.push({
            pathname: Config.getRootPath() + route,
            state: param
        });
    }

    /**
     * Get state from location and set state of component with browserHistory.
     * @param component
     * @param cb callback
     * @returns null
     */
    static getStoreState(component, cb) {
        const route = component.props.route.path;
        const data = component.props.location && component.props.location.state ? component.props.location.state : null;
        const state = {...data, storeFilter: true};

        if (data && (!data.hasOwnProperty("menu") || Object.keys(data).length > 1)) {
            component.setState(state, () => {
                cb && cb();
            });
            browserHistory.push({
                pathname: Config.getRootPath() + route,
            });
        }
        return data;
    }

    static isEmpty(value, escapeZero = false) {
        switch (typeof value) {
            case "object": {
                if (Array.isArray(value))
                    return value.length <= 0;
                else
                    return (value && Object.keys(value).length <= 0) || !value;
            }
            case "string": {
                return !value;
            }
            case "number": {
                if (!escapeZero)
                    return value === 0 ? false : !value;
                else
                    return !value;
            }
            default: {
                return !value;
            }
        }
    }

    /**
     * Get user picture url.
     * @param UserPictureUrl
     */

    static getUserPicture(UserPictureUrl, defaultValue) {
        if (!UserPictureUrl) return defaultValue ? defaultValue : null;

        if (UserPictureUrl.indexOf('http') < 0) {
            return this.getCDNPath() + UserPictureUrl;
        }

        return UserPictureUrl;
    }

    static getUser = (params) => {
        // paramsDef = {
            // EmployeeID: "#1234",
            // EmployeeName: "Cristiano Ronaldo",
            // UserPictureURL: null,
        // };
        let item = null;
        let list =  this.listUsers || [];
        if(list && list.length > 0){
            list.forEach(i=>{
                let flag = true;
                Object.keys(params).forEach((key)=>{
                    if(i[key] !== params[key]) flag = false;
                });
                if(flag){
                    item = flag ? i : item;
                    return true;
                }

            });
        }
        item = item || {};
        if(!_.get(item,"UserPictureURL", false)) return item;
        item.UserPictureURL = this.getUserPicture(item.UserPictureURL);
        return item;
    };

    static getListUser = (arrayID, key = "EmployeeID") => {
        let arr = [];
        let list =  this.listUsers || [];
        if(list && list.length > 0){
            list.forEach(i=>{
                if(arrayID.includes(i[key])){
                    i.UserPictureURL = this.getUserPicture(i.UserPictureURL);
                    arr.push(i);
                }
            });
        }
        return arr;

    };

    static decryptCrypto = (text) => {
        try {
            const key = crypto.createDecipher('aes-256-cbc', this.env.secret + '@^1#');
            const str = key.update(text, 'hex', 'utf8');

            return str + key.final('utf8');
        } catch (e) {
            console.log("decrypt error", e);
            return false;
        }
    };

    static encryptCrypto = (text) => {
        try {
            const key = crypto.createCipher('aes-256-cbc', this.env.secret + '@^1#');
            const str = key.update(text, 'utf8', 'hex');

            return str + key.final('hex');
        } catch (e) {
            console.log("encrypt error");
            return false;
        }
    };

    //Encryptdata...
    static encryptData = (data, encryptKey) => {
        if (!data || typeof data !== "string") return false;
        try {
            encryptKey = encryptKey ? encryptKey : this.encryptKey;
            return CryptoJS.AES.encrypt(data, encryptKey).toString();
        } catch (e) {
            console.log("encrypt error", e);
            return false;
        }
    };

    //Decrypt data...
    static decryptData = (data, encryptKey, stringDecode = CryptoJS.enc.Utf8) => {
        if (!data) return null;
        try {
            encryptKey = encryptKey ? encryptKey : this.encryptKey;
            return CryptoJS.AES.decrypt(data, encryptKey).toString(stringDecode);
        } catch (e) {
            console.log("decrypt error", e);
            return null;
        }
    };

    /**
     * Create store history
     * @param newData
     * @param captions: {
     *     @RecInfoTitle: null, //null - data_RectInfoTitle
     *     @CaseStatusName: "DHR_Ten_trang_thai",
     *     @HandlerID: "DHR_Nguoi_xu_ly",
     *     ....
     * },
     * @param action: 0 | 1 | 2 | 3
     * @param options: {
     *     @TransID: TransID,
     *     @keyTransId: TransID
     *     @TransactionID: "",
     *     @TransactionName: "",
     *     @ModuleID: "W25",
     *     @keyExpr: keyExprt for array
     *     @ortherData: Some orther data,
     *     @itemRender: function,
     *     @excludeFields: ["CaseID", "CaseCode"],
     *     @callbackAfterSave:
     * }
     * @param dataOldCompare
     * @param returnData
     * @returns {boolean|Array}
     */
    static createContentHistory(newData, captions, action, options, dataOldCompare) {
        if (!newData || !captions || (action !== 0 && !action)) return null;
        if (action === 1 && !dataOldCompare) return null;

        const {itemRender, excludeFields} = options;
        let obj = {};
        if (action !== 1) { //Khong phai edit
            Object.keys(captions).forEach(cap => {
                if (excludeFields && excludeFields.includes(cap)) return false;
                const key = captions[cap] ? captions[cap] : "data_" + cap;
                const param = {data: newData, oldData: null, action: action, key: cap, item: {[key]: captions[cap]}};
                obj[key] = itemRender && itemRender(param, "Content") ? itemRender(param, "Content") : (newData[cap] || null);
            });
        } else {
            Object.keys(captions).forEach(cap => {
                if (excludeFields && excludeFields.includes(cap)) return false;
                if (dataOldCompare[cap] !== newData[cap]) {
                    const key = captions[cap] ? captions[cap] : "data_" + cap;
                    const param = {data: newData, oldData: dataOldCompare, action: action, key: cap, item: {[cap]: captions[cap]}};
                    obj[key] = {
                        old: itemRender && itemRender(param, "Content_OLD") ? itemRender(param, "Content_OLD") : (dataOldCompare[cap] || null),
                        new: itemRender && itemRender(param, "Content_NEW") ? itemRender(param, "Content_NEW") : (newData[cap] || null)
                    }
                }
            });
        }
        return Object.keys(obj).length > 0 ? obj : null;
    }
    static createSaveHistory2 = async (newData, captions, action, options, dataOldCompare, returnData = false) => {
        if (!newData || !captions || (action !== 0 && !action)) return false;
        if (action === 1 && !dataOldCompare) return false;
        if (action === 1 && Object.is(newData) !== Object.is(dataOldCompare)) return false;
        // if (!options ||!options.TransID) return false;

        //Process..
        const {TransID, ModuleID, TransactionID, TransactionName, keyExpr,
                  callbackAfterSave, ortherData, itemRender, keyTransID
              } = options || {};
        let data = [];
        let dataType = newData ? Array.isArray(newData) ? "array" : (typeof newData === "object" ? "object" : null) : null;
        let param = {};
        let el = null;
        switch (dataType) {
            case "object":
                const _TransID = TransID ? TransID : (newData[keyTransID] ? newData[keyTransID] : newData[keyExpr]);
                if (action < 0 || (action !== 0 && !action)) {
                    [0,1,3].forEach(_action => {
                        param = {data: newData, oldData: dataOldCompare, action: _action,captions: captions};
                        const content = this.createContentHistory(newData, captions, _action, options, dataOldCompare);
                        if (content) {
                            el = {
                                ModuleID:        itemRender && itemRender(param, "ModuleID") ? itemRender(param, "ModuleID") : (ModuleID || ""),
                                TransactionID:   itemRender && itemRender(param, "TransactionID") ? itemRender(param, "TransactionID") : (TransactionID || ""),
                                TransactionName: itemRender && itemRender(param, "TransactionName") ? itemRender(param, "TransactionName") : (TransactionName || ""),
                                TransID:         itemRender && itemRender(param, "TransID") ? itemRender(param, "TransID") : (_TransID || _TransID === 0 ? _TransID : ""),
                                Content:         content,
                                Action:          itemRender && itemRender(param, "Action") ? itemRender(param, "Action") : (_action || 0),
                            };
                            data.push(el);
                        }
                    });
                } else {
                    param = {data: newData, oldData: dataOldCompare, action: action,captions: captions};
                    const content = this.createContentHistory(newData, captions, action, options, dataOldCompare);
                    if (content) {
                        el = {
                            ModuleID:        itemRender && itemRender(param, "ModuleID") ? itemRender(param, "ModuleID") : (ModuleID || ""),
                            TransactionID:   itemRender && itemRender(param, "TransactionID") ? itemRender(param, "TransactionID") : (TransactionID || ""),
                            TransactionName: itemRender && itemRender(param, "TransactionName") ? itemRender(param, "TransactionName") : (TransactionName || ""),
                            TransID:         itemRender && itemRender(param, "TransID") ? itemRender(param, "TransID") : (_TransID || _TransID === 0 ? _TransID : ""),
                            Content:         content,
                            Action:          itemRender && itemRender(param, "Action") ? itemRender(param, "Action") : (action || 0),
                        };
                        data.push(el);
                    }
                }

                break;
            case "array":
                //Array..
                newData = newData ? newData : [];
                let oldData = dataOldCompare ? dataOldCompare : [];
                if (action < 0 || (action !== 0 && !action)) { // Truong hop ko truyen action se tu dinh nghia action boi 2 array
                    const newDataKeys = newData.map(d => d[keyExpr]);
                    const oldDataKeys = oldData.map(d => d[keyExpr]);
                    const added = newData.filter(d => oldDataKeys.indexOf(d[keyExpr]) <= -1).map(d => ({...d, action: 2}));
                    const edited = newData.filter(d => oldDataKeys.indexOf(d[keyExpr]) > -1).map(d => ({...d, action: 1}));
                    const removed = oldData.filter(d => newDataKeys.indexOf(d[keyExpr]) <= -1).map(d => ({...d, action: 3}));
                    const arr = added.concat(edited).concat(removed); //Gop mang
                    arr.forEach(_d => {
                        const _action = _d.action;
                        const _dataOld = oldData && oldData.find(old => old[keyExpr] === _d[keyExpr]);
                        if (_action === 1 && !_dataOld) return false;
                        param = {data: _d, oldData: _dataOld, action: _action, captions: captions};
                        const content = this.createContentHistory(_d, captions, _action, options, _dataOld);
                        const _TransID = TransID ? TransID : (_d[keyTransID] ? _d[keyTransID] : _d[keyExpr]);
                        if (content) {
                            el = {
                                ModuleID:        itemRender && itemRender(param, "ModuleID") ? itemRender(param, "ModuleID") : (ModuleID || ""),
                                TransactionID:   itemRender && itemRender(param, "TransactionID") ? itemRender(param, "TransactionID") : (TransactionID || ""),
                                TransactionName: itemRender && itemRender(param, "TransactionName") ? itemRender(param, "TransactionName") : (TransactionName || ""),
                                TransID:         itemRender && itemRender(param, "TransID") ? itemRender(param, "TransID") : (_TransID || _TransID === 0 ? _TransID : ""),
                                Content:         content,
                                Action:          itemRender && itemRender(param, "Action") ? itemRender(param, "Action") : (_action || 2),
                            };
                            data.push(el);
                        }
                    });

                } else { // Truong hop truyen action cu the cho truong hop array
                    newData.forEach(_d => {
                        const _dataOld = oldData && oldData.find(old => old[keyExpr] === _d[keyExpr]);
                        if (action === 1 && !_dataOld) return false;
                        param = {data: _d, oldData: _dataOld, action: action, captions: captions};
                        const content = this.createContentHistory(_d, captions, action, options, _dataOld);
                        const _TransID = TransID ? TransID : (_d[keyTransID] ? _d[keyTransID] : _d[keyExpr]);
                        if (content) {
                            el = {
                                ModuleID:        itemRender && itemRender(param, "ModuleID") ? itemRender(param, "ModuleID") : (ModuleID || ""),
                                TransactionID:   itemRender && itemRender(param, "TransactionID") ? itemRender(param, "TransactionID") : (TransactionID || ""),
                                TransactionName: itemRender && itemRender(param, "TransactionName") ? itemRender(param, "TransactionName") : (TransactionName || ""),
                                TransID:         itemRender && itemRender(param, "TransID") ? itemRender(param, "TransID") : (_TransID || _TransID === 0 ? _TransID : ""),
                                Content:         content,
                                Action:          itemRender && itemRender(param, "Action") ? itemRender(param, "Action") : (action || 0),
                            };
                            data.push(el);
                        }
                    });
                }
                break;
            default:
                break;
        }

        if (ortherData && ortherData.length > 0) {
            data = ortherData.concat(data);
        }

        if (returnData) {
            return data;
        } else {
            if (data.length > 0) {
                const params = {
                    attributes: JSON.stringify(data),
                };
                return await this.saveHistory2(params, callbackAfterSave);
            }
        }

    };
    static saveHistory2 = async (params, cb) => {
        let status = 200;
        if (this.controller) {
            await this.controller.saveHistory2(params, (error, data) => {
                cb && cb(error, data);
                status = error ? 400 : 200;
            });
        }
        return status;
    };

    /**
     * Create store history
     * @param newData
     * @param oldData
     * @param captions: {
     *     @CaseStatusName: "DHR_Ten_trang_thai",
     *     @HandlerID: "DHR_Nguoi_xu_ly",
     *     ....
     * },
     * @param options: {
     *     @codeID: codeID,
     *     @keyExpr: keyExprt for array
     *     @ortherData: Some orther data,
     *     @mode: ["add", "edit", "removed"]
     *     @keyForAdd: "ContractNo",
     *     @keyForRemove: "ContractNo",
     *     @type: {
     *         CaseStatusName: 'highlight',
     *         HandlerID: 'image',
     *         Test: 'text'
     *     }
     *     @itemRender: function,
     *     @excludeFields: ["CaseID", "CaseCode"],
     *     @callbackAfterSave:
     * }
     * @param returnData
     * @returns {boolean|Array}
     */
    static createSaveHistory = async (newData, oldData, captions, options, returnData = false) => {
        if (!(newData && oldData && typeof newData === typeof oldData)) return false;
        if (!options ||!options.codeID) return false;
        if (!newData && !oldData) return false;

        //Process..
        const {codeID, keyExpr, formID, mode, keyForAdd, keyForRemove, type, excludeFields,
            callbackAfterSave, ortherData, itemRender
        } = options || {};
        let data = [];
        let keys = [];
        let dataType = newData ? Array.isArray(newData) ? "array" : (typeof newData === "object" ? "object" : null) : null;
        dataType = dataType ? dataType : (oldData ? Array.isArray(oldData) ? "array" : (typeof oldData === "object" ? "object" : null) : null);
        let desc = "";
        let param = {};
        let el = null;
        switch (dataType) {
            case "object":
                //Object..
                let action = -1;
                if ((!mode || mode.length < 1 || mode.indexOf("add") > -1) && (!oldData || Object.keys(oldData).length < 1) && newData) {
                    action = 0;
                } else if ((!mode || mode.length < 1 || mode.indexOf("remove") > -1) && (!newData || Object.keys(newData).length < 1) && oldData) {
                    action = 3;
                } else if ((!mode || mode.length < 1 || mode.indexOf("edit") > -1) && newData && oldData) {
                    action = 1;
                }

                switch (action) {
                    case 0:
                        desc = captions && captions[keyForAdd] ? captions[keyForAdd] : "";
                        param = {data: newData, oldData: oldData, mode: "add", dataField: keyForAdd, captions: captions};
                        el = {
                            codeID: codeID ? codeID : newData[keyExpr],
                            formID: formID,
                            type: "text",
                            linkedTrans: "",
                            oldValue: itemRender && itemRender(param, "oldValue") ? itemRender(param, "oldValue") : "",
                            newValue: itemRender && itemRender(param, "newValue") ? itemRender(param, "newValue") : newData[keyForAdd],
                            description84: itemRender && itemRender(param, "description84") ? itemRender(param, "description84") : (desc ? Config.lang(desc, "vi") : ""),
                            description01: itemRender && itemRender(param, "description01") ? itemRender(param, "description01") : (desc ? Config.lang(desc, "en") : ""),
                            action: itemRender && itemRender(param, "action") ? itemRender(param, "action") : 0,
                        };
                        data.push(el);
                        break;
                    case 1:
                        keys = Object.keys(newData);
                        if (excludeFields && excludeFields.length > 0) {
                            keys = keys.filter(k => excludeFields.indexOf(k) <= -1);
                        }
                        keys.forEach(k => {
                            if (newData[k] !== oldData[k]) {
                                desc = captions && captions[k] ? captions[k] : "";
                                param = {data: newData[k], oldData: oldData[k], mode: "edit", dataField: k, captions: captions};
                                el = {
                                    codeID: codeID ? codeID : newData[keyExpr],
                                    formID: formID,
                                    type: type && type[k] ? type[k] : "text",
                                    linkedTrans: "",
                                    oldValue: itemRender && itemRender(param, "oldValue") ? itemRender(param, "oldValue") : oldData[k],
                                    newValue: itemRender && itemRender(param, "newValue") ? itemRender(param, "newValue") : newData[k],
                                    description84: itemRender && itemRender(param, "description84") ? itemRender(param, "description84") : (desc ? Config.lang(desc, "vi") : ""),
                                    description01: itemRender && itemRender(param, "description01") ? itemRender(param, "description01") : (desc ? Config.lang(desc, "en") : ""),
                                    action: itemRender && itemRender(param, "action") ? itemRender(param, "action") : 1,
                                };
                                data.push(el);
                            }
                        });
                        break;
                    case 3:
                        desc = captions && captions[keyForRemove] ? captions[keyForRemove] : "";
                        param = {data: newData, oldData: oldData, mode: "remove", dataField: codeID, captions: captions};
                        el = {
                            codeID: codeID ? codeID : oldData[keyExpr],
                            formID: formID,
                            type: "text",
                            linkedTrans: "",
                            oldValue: itemRender && itemRender(param, "oldValue") ? itemRender(param, "oldValue") : oldData[keyForRemove],
                            newValue: itemRender && itemRender(param, "newValue") ? itemRender(param, "newValue") : "",
                            description84: itemRender && itemRender(param, "description84") ? itemRender(param, "description84") : (desc ? Config.lang(desc, "vi") : ""),
                            description01: itemRender && itemRender(param, "description01") ? itemRender(param, "description01") : (desc ? Config.lang(desc, "en") : ""),
                            action: itemRender && itemRender(param, "action") ? itemRender(param, "action") : 3,
                        };
                        data.push(el);
                        break;
                    default:
                        break;
                }
                break;
            case "array":
                //Array..
                newData = newData ? newData : [];
                oldData = oldData ? oldData : [];
                const newDataKeys = newData.map(d => d[keyExpr]);
                const oldDataKeys = oldData.map(d => d[keyExpr]);
                const added = newData.filter(d => oldDataKeys.indexOf(d[keyExpr]) <= -1);
                const edited = newData.filter(d => oldDataKeys.indexOf(d[keyExpr]) > -1);
                const removed = oldData.filter(d => newDataKeys.indexOf(d[keyExpr]) <= -1);

                //Added..
                if (!mode || mode.length < 1 || mode.indexOf("add") > -1) {
                    added.forEach(item => {
                        desc = captions && captions[keyExpr] ? captions[keyExpr] : "";
                        param = {data: item, oldData: null, mode: "addGrid", dataField: keyForAdd || keyExpr, captions: captions};
                        el = {
                            codeID: codeID,
                            formID: formID,
                            type: type && type[keyForAdd] ? type[keyForAdd] : "text",
                            linkedTrans: "",
                            oldValue: "",
                            newValue: itemRender && itemRender(param, "newValue") ? itemRender(param, "newValue") : (keyForAdd && item[keyForAdd] ? item[keyForAdd] : item[keyExpr]),
                            description84: itemRender && itemRender(param, "description84") ? itemRender(param, "description84") : (desc ? Config.lang(desc, "vi") : ""),
                            description01: itemRender && itemRender(param, "description01") ? itemRender(param, "description01") : (desc ? Config.lang(desc, "en") : ""),
                            action: itemRender && itemRender(param, "action") ? itemRender(param, "action") : 2,
                        };
                        data.push(el);
                    });
                }
                //Edited..
                if (!mode || mode.length < 1 || mode.indexOf("edit") > -1) {
                    edited.forEach(item => {
                        const rowDataOld = oldData.find(d => d[keyExpr] === item[keyExpr]);
                        keys = Object.keys(item);
                        if (excludeFields && excludeFields.length > 0) {
                            keys = keys.filter(k => excludeFields.indexOf(k) <= -1);
                        }
                        keys.forEach(k => {
                            if (item[k] !== rowDataOld[k]) {
                                desc = captions && captions[k] ? captions[k] : "";
                                param = {data: item, oldData: rowDataOld, mode: "editGrid", dataField: k, captions: captions};
                                el = {
                                    codeID: codeID ? codeID : item[keyExpr],
                                    formID: formID,
                                    type: type && type[k] ? type[k] : "text",
                                    linkedTrans: "",
                                    oldValue: itemRender && itemRender(param, "oldValue") ? itemRender(param, "oldValue") : rowDataOld[k],
                                    newValue: itemRender && itemRender(param, "newValue") ? itemRender(param, "newValue") : item[k],
                                    description84: itemRender && itemRender(param, "description84") ? itemRender(param, "description84") : (desc ? Config.lang(desc, "vi") : ""),
                                    description01: itemRender && itemRender(param, "description01") ? itemRender(param, "description01") : (desc ? Config.lang(desc, "en") : ""),
                                    action: itemRender && itemRender(param, "action") ? itemRender(param, "action") : 1,
                                };
                                data.push(el);
                            }
                        });
                    });
                }
                //Removed..
                if (!mode || mode.length < 1 || mode.indexOf("remove") > -1) {
                    removed.forEach(item => {
                        desc = captions && captions[keyExpr] ? captions[keyExpr] : "";
                        param = {data: item, oldData: null, mode: "removeGrid", dataField: keyForAdd || keyExpr, captions: captions};
                        el = {
                            codeID: item[keyExpr],
                            formID: formID,
                            type: type && type[keyForRemove] ? type[keyForRemove] : "text",
                            linkedTrans: "",
                            oldValue: itemRender && itemRender(param, "oldValue") ? itemRender(param, "oldValue") : (keyForRemove && item[keyForRemove] ? item[keyForRemove] : item[keyExpr]),
                            newValue: "",
                            description84: itemRender && itemRender(param, "description84") ? itemRender(param, "description84") : (desc ? Config.lang(desc, "vi") : ""),
                            description01: itemRender && itemRender(param, "description01") ? itemRender(param, "description01") : (desc ? Config.lang(desc, "en") : ""),
                            action: itemRender && itemRender(param, "action") ? itemRender(param, "action") : 3,
                        };
                        data.push(el);
                    });
                }
                break;
            default:
                break;
        }

        if (ortherData && ortherData.length > 0) {
            data = ortherData.concat(data);
        }

        if (returnData) {
            return data;
        } else {
            if (data.length > 0) {
                const params = {
                    attributes: JSON.stringify(data),
                };
                return await this.saveHistory(params, callbackAfterSave);
            }
        }

    };
    static saveHistory = async (params, cb) => {
        let status = 200;
        if (this.controller) {
            await this.controller.saveHistory(params, (error, data) => {
                cb && cb(error, data);
                status = error ? 400 : 200;
            });
        }
        return status;
    };

    static getMenuInfo = (isReload = true, cb) => {
        const paramMenu = {
            ModuleID: "",
            Language: Config.language || "84",
            isWeb: 1,
            ProductID: Config.env.productID || "",
        };
        this.controller.getMenuV2(paramMenu, (errGetMenuV2, dataGetMenuV2)=>{
            if (errGetMenuV2){
                Config.popup.show('INFO', errGetMenuV2.message);
                return false;
            } else if (dataGetMenuV2){

                Config.setLocalStorage('MENUDHR', JSON.stringify(dataGetMenuV2));
                this.controller.getFormInfo((errGetInfo, dataGetInfo)=>{
                    if (errGetInfo){
                        Config.popup.show('INFO', errGetInfo.message);
                        return false;
                    } else if (dataGetInfo){
                        Config.setLocalStorage('FORMINFODHR', JSON.stringify(dataGetInfo));
                        Config.formInfo = dataGetInfo;
                        cb && cb();
                        if (isReload) window.location.reload();
                    }
                });
            }
        });
    };

    static getMenuItem = (obj) => {
        if (!this.menu) return null;
        const menuEss = this.menu.listESS ? this.menu.listESS : [];
        const menuMss = this.menu.listMSS ? this.menu.listMSS : [];
        const dataMenu = menuEss.concat(menuMss);
        let menu = [];
        dataMenu.forEach(data => {
            if (data.MenuItem) {
                menu = menu.concat(data.MenuItem);
            }
        });

        menu = menu.filter(m => {
            let flag = true;
            Object.keys(obj).forEach(o => {
                if (m[o] !== obj[o]) flag = false;
            });
            return flag;
        });

        return menu;
    };

    static checkNotify = (params, cb) => {
        // console.log('params', !params || !params.TransID || !params.FormID);
        if (!params || !params.TransID || !params.FormID) return false;

        if (cb) cb(params);
        if (params.FormID) browserHistory.push(Config.getRootPath() + params.FormID);
    };

    static sub_text = (str, count_char, str_more = "...") => {
        if (!str) return str;
        if (!Number.isInteger(count_char) || count_char < 1) return str;
        return str.substring(0, count_char) + (str.length > count_char ? str_more : "");
    };

    /**
     * Get params in url search..
     * @param props
     * @param arrayKeys
     */
    static getUrlParams = (props, arrayKeys) => {
        const { location } = props ? props : window;
        const params = new window.URLSearchParams(window.location.search);
        let state = {};
        for (let param of params.entries()) {
            if (arrayKeys && arrayKeys.length > 0) {
                if (arrayKeys.includes(param[0])) {
                    state[param[0]] = param[1];
                }
            } else {
                state[param[0]] = param[1];
            }
        }
        if (location && location.state) {
            Object.keys(location.state).forEach(s => {
                if (arrayKeys && arrayKeys.length > 0) {
                    if (arrayKeys.includes(s)) {
                        state[s] = location.state[s];
                    }
                } else {
                    state[s] = location.state[s];
                }
            });
        }
        if (!state.hasOwnProperty("voucher_id") && state.hasOwnProperty("VoucherID")) {
            state.voucher_id = state.VoucherID || "";
        }
        if (!state.hasOwnProperty("FormID") && state.hasOwnProperty("form_id")) {
            state.FormID = state.form_id || "";
        }
        return state;
    };

    /**
     * Call form when click notify or maillink...
     * @param options: {
     *     @ID: voucher_id,
     *     @voucher_id: voucher_id,
     *     @FormID (required): FormID curent..,
     *     @data (required): grid dataSource,
     *     @keyExpr (required): key of data,
     *     @params: Params to response func..,
     *     @onLoad: func load data grid,
     *     @onAction: func action when had data
     * }
     * @param props: props of component to get location...
     */
    static callChildForm = (options, props) => {
        const _params = this.getUrlParams(props);
        const {ID, FormID, params, data, keyExpr, onLoad, onAction} = options || {};
        let {voucher_id, VoucherID} = params || {};
        let {voucher_id: voucher_id1, VoucherID: VoucherID1} = _params || {};
        voucher_id = ID ? ID : (voucher_id || VoucherID || voucher_id1 || VoucherID1);
        const dataParams = {
            ...options,
            ..._params,
            ...params,
            ID: voucher_id
        };
        const IsCallForm = Config.getLocalStorage("DHR_CALL_FORM", "");
        const key = FormID + voucher_id; // key: to save and check in localstorage
        if (voucher_id && (!IsCallForm || IsCallForm !== key)) {
            if (onLoad) onLoad(dataParams);
            Config.setLocalStorage("DHR_CALL_FORM", key);
        } else if (voucher_id && data.length > 0 && IsCallForm === key ) {
            const rowData = data.find(d => d[keyExpr] === voucher_id);
            if (rowData) {
                browserHistory.push(Config.getRootPath() + FormID);
                Config.removeLocalStorage("DHR_CALL_FORM", "");
                if (onAction) onAction(dataParams, rowData);
            }
        }
    };

    static setItemPerPage = (e, itemPerPage = 10, cb)  => {
        if (!e || !e.component || !itemPerPage) return false;
        const dataSource = e.component.getDataSource();
        const _itemPerPage = e.gridRef ? e.gridRef.itemPerPage() : itemPerPage;
        if (_itemPerPage && itemPerPage !== _itemPerPage) {
            dataSource.pageSize(_itemPerPage);
        }
        const items = dataSource.items();
        const totalCount = dataSource.totalCount();
        const pageIndex = dataSource.pageIndex();
        const pageSize = dataSource.pageSize();
        let countItems = 0; //counter

        if (items && items.length > 0) {
            items.forEach((val) => {
                countItems += val.items !== null ? val.items.length : val.collapsedItems.length;
            });
        }
        const _currentTotal = (itemPerPage * pageIndex) + countItems;
        let _pageSize = _itemPerPage;
        if (countItems < itemPerPage && _currentTotal < totalCount) {
            _pageSize = pageSize + (pageSize - countItems + 1);
            dataSource.pageSize(_pageSize);
            if (e.gridRef) e.gridRef.itemPerPage(_pageSize);
            dataSource.reload(); //reload dataSource
        }
        cb && cb({pageIndex: pageIndex, pageSize: _pageSize, totalCount: totalCount, itemPerPage: countItems});
        return _pageSize;
    };
}

export default Config;
