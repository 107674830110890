import React from "react";

import { Box, Typography } from "@material-ui/core";

import { useStyles } from "./styles";
import {Image} from "react-bootstrap";
import Config from "../../config";
const HeaderReport = () => {
  const classes = useStyles();
  return (
    <>
      <Box my={4}>
          <Image src={require('./logo-report.png')} style={{height: 65}} />
      </Box>
      <Typography
        className={classes.mainTitle}
        gutterBottom
        align="center"
        variant="h3"
      >
          {Config.lang("DHR_Khao_sat_y_kien_khach_hang")}
      </Typography>
      <Box className={classes.boxSub}>
        <Typography className={classes.subTitle} variant="h6" align={"center"}>
            {Config.lang("DHR_Nhung_y_kien_nay_se_duoc_cong_ty_chung_toi_cong_nhan")}
            <div className={'text-center'} style={{fontSize: 20}}>❧</div>
        </Typography>
      </Box>
    </>
  );
};

export default HeaderReport;
