/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 12/10/2019
 * @Example
 */

import React from "react";
import PropTypes from "prop-types";
import {List, ListItemAvatar, ListItemIcon, ListItemText} from "@material-ui/core"
import ListItem from "@material-ui/core/ListItem";
import Config from "../../config";
import {browserHistory} from "react-router";
import Popover from "../common/popover/popover";
import Divider from "@material-ui/core/Divider";
import * as mainActions from "../../redux/main/main_actions";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListSubheader from "@material-ui/core/ListSubheader";
import Typography from "@material-ui/core/Typography";
import {Image} from "react-bootstrap";
import InlineSVG from "react-inlinesvg";
import W09F2003 from "../W0X/W09/W09F2003/W09F2003";
import W09F2230 from "../W0X/W09/W09F2230/W09F2230";

class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openChildren: false,
            openChangePassword: false,
            openSetting: false,
            theme: Config.color && Object.keys(Config.color).length > 0 ? Config.color : null,
            anchorEl: {
                root: null,
                theme: null
            }
        };
    }

    componentDidMount() {
        // this.props.mainActions.getTheme();
    }

    onLink = (link) => {
        link = link ? link.replace('/', '') : link;
        if(link === 'logout'){
            Config.logout();
        }
        else {
            browserHistory.push(Config.getRootPath() + link);
            document.body.click();
        }
        this.setState({
            anchorEl: {}
        })
    };

    toogleProfile = (e, key, isClose) => {
        if (isClose) {
            this.setState({openChildren: false});
            if (key) {
                this.setState({
                    anchorEl: {
                        ...this.state.anchorEl,
                        [key]: null
                    }
                });
            } else {
                this.setState({
                    anchorEl: {}
                });
            }
        } else {
            this.setState({openChildren: key !== 'root'});
            this.setState({
                anchorEl: {
                    ...this.state.anchorEl,
                    [key]: e.currentTarget
                }
            });
        }
    };

    onChangeTheme = (item) => {
        const {theme} = this.state;
        if (item && (!theme || (theme && item.MainColor !== theme.MainColor))) {
            this.setState({
                theme: item
            }, () => {
                this.props.mainActions.changeTheme({theme: item});
            });
        }
    };

    onChangePassword = () =>{
        this.toogleProfile(null, 'root',true)
        this.setState((prevState) => ({ openChangePassword: !prevState.openChangePassword }));
    }

    onSetting = () => {
        this.toogleProfile(null, 'root',true)
        this.setState((prevState) => ({ openSetting: !prevState.openSetting }));
    }

    render(){
        const {theme, openChildren, anchorEl, openChangePassword, openSetting} = this.state;
        const {themes} = this.props;
        const salesURL = Config.getUserPicture(Config.profile.URL, require('../../assets/images/general/user_default.svg'));
        const userName = Config.profile.UserNameU || 'N/A';
        const userDutyName = Config.profile.UserDutyNameU || 'N/A';
        return(
            <React.Fragment>
                <W09F2003 open={openChangePassword} onClose={this.onChangePassword}/>
                <W09F2230 open={openSetting} onClose={this.onSetting}/>
                <Popover
                    className={"popover-profile" + (openChildren ? " hidden" : "")}
                    PaperProps={{
                        square: true
                    }}
                    open={!!(anchorEl && anchorEl['root']) }
                    anchorEl={anchorEl && anchorEl['root']}
                    onClose={(e) => this.toogleProfile(e, 'root',true)}
                    items={()=> {
                        return (
                            <List disablePadding style={{color: '#696B73'}}>
                                {/* Ho so nhan vien */}

                                <div style={{
                                    padding: '13px 13px 6px'
                                }}>
                                    <img alt="" src={salesURL} style={{
                                        width: 60,
                                        height: 60,
                                        minWidth: 60,
                                        minHeight: 60,
                                        borderRadius: 60,
                                        objectFit:'cover'
                                    }}/>

                                    <div className={'txtName'}>{userName}</div>
                                    <div className={'txtDutyName'}>{userDutyName}</div>
                                </div>
                                <ItemLink
                                    style={{height: 35}}
                                    img={require('../../assets/images/popup-profile/icon-user.svg')}
                                    // icon={'fas fa-user'}
                                    name={Config.lang("DHR_Ca_nhan")}
                                    onClick={() => this.onLink('W09F2000')}
                                />
                                <ItemLink
                                    style={{height: 35}}
                                    img={require('../../assets/images/popup-profile/icon-group.svg')}
                                    // icon={'fas fa-user'}
                                    name={Config.lang("DHR_Nhom")}
                                    onClick={() => this.onLink('W09F3000')}
                                />

                                {/*Chu de*/}
                                <Popover
                                    open={!!(anchorEl && anchorEl['theme'])}
                                    anchorEl={anchorEl && anchorEl['root']}
                                    onClose={(e) => this.toogleProfile(e, '', true)}
                                    items={() => {
                                        return (
                                            <List subheader={
                                                <Typography component={"a"} style={{width: '100%'}}
                                                            onClick={(e) => this.toogleProfile(e, 'theme', true)}>
                                                    <div className={"display_row align-center"} style={{width: '100%', height: 36}}>
                                                        <ChevronLeftIcon color={"primary"} style={{marginLeft: '-5px'}} />
                                                        <InlineSVG className={"mgr5"} src={require('../../assets/images/popup-profile/icon-color-fill.svg')} />
                                                        <ListSubheader color={"primary"} disableGutters>{Config.lang("DHR_Mau_giao_dien")}</ListSubheader>
                                                    </div>
                                                    <Divider className={'mgb5'} style={{width: '100%'}} />
                                                </Typography>
                                                }
                                                style={{width: 192, padding: '0 15px 15px 15px'}}
                                                disablePadding
                                                className={'display_row align-center flex-wrap'}>
                                                {themes && themes.map((e, i) => {
                                                    return (
                                                        <ListItem button
                                                                  key={i}
                                                                  selected={theme && e.MainColor === theme.MainColor}
                                                                  style={{width: 54, height: 54, padding: 2}}
                                                                  className={''}
                                                                  onClick={() => this.onChangeTheme(e)}
                                                                  value={e}>
                                                            <ListItemIcon style={{width: '100%', height: '100%',minWidth: 34}}>
                                                                                <span style={{
                                                                                    backgroundColor: e.MainColor,
                                                                                    width: '100%',
                                                                                    height: '100%',
                                                                                    display: 'block'
                                                                                }}/>
                                                            </ListItemIcon>
                                                        </ListItem>
                                                    );
                                                })}
                                            </List>
                                        )
                                    }}
                                    cursor={() => {
                                        return (
                                            <ListItem style={{height: 35}} dense button onClick={(e) => this.toogleProfile(e, 'theme')}>
                                                <ListItemAvatar style={{
                                                    minWidth: 25,
                                                    textAlign: 'center',
                                                    fontSize: 20,
                                                    display:'flex',
                                                    alignItems:'center'
                                                }}>
                                                    <IconPopup img={require('../../assets/images/popup-profile/icon-color-fill.svg')}/>
                                                </ListItemAvatar>
                                                <ListItemText disableTypography={true} style={{fontSize: '1.1rem', marginTop:0}}>
                                                    {Config.lang("DHR_Mau_giao_dien")}
                                                </ListItemText>
                                                <ChevronRightIcon style={{marginRight:-10}} />
                                            </ListItem>
                                        )
                                    }}
                                    className="popup-choose-theme popup-tooltip"
                                />

                                {/* Cai dat */}
                                <ItemLink
                                    style={{height: 35}}
                                    img={require('../../assets/images/popup-profile/icon-settings.svg')}
                                    // icon={'fas fa-cogs'}
                                    name={Config.lang("DHR_Cai_dat")}
                                    onClick={this.onSetting}
                                />

                                {/* Doi mat khau */}
                                <ItemLink
                                    style={{height: 35}}
                                    img={require('../../assets/images/popup-profile/icon-change-password.svg')}
                                    // icon={'fas fa-cogs'}
                                    name={Config.lang("DHR_Doi_mat_khau")}
                                    onClick={this.onChangePassword}
                                />

                                {/*Logout*/}
                                <Divider style={{padding: '0 13px'}} />
                                <ItemLink
                                    style={{height: 35}}
                                    img={require('../../assets/images/popup-profile/icon-exit-door.svg')}
                                    // icon={'fas fa-sign-out-alt'}
                                    name={Config.lang("DHR_Dang_xuat1")}
                                    onClick={() => this.onLink('logout')}
                                />
                            </List>
                        )
                    }}
                    cursor={()=>{
                        return(
                            <div className="header-logout display_row align-center"
                                 style={{fontSize: 30,cursor:'pointer', height: '4rem'}}
                                 onClick={(e) => this.toogleProfile(e, 'root')}
                            >
                                {/*<span>{salesPersonNameU}</span>*/}
                                <img alt="" src={salesURL}/>
                            </div>
                        )
                    }}
                />
            </React.Fragment>
        )
    }
}

class IconPopup extends React.Component {
    render() {
        const {img} = this.props;
        return(
            <Image style={{
                width:24,
                marginRight: 12,
                color:'grey'
            }} src={img}/>
        )
    }
}
class ItemLink extends React.Component {

    render() {
        const {children, name, icon, style, className, onClick, img} = this.props;

        return (
            <React.Fragment>
                {children && children}
                {!children && <ListItem dense style={style} className={className} button onClick={onClick}>
                    <ListItemAvatar style={{
                        minWidth: 25,
                        textAlign: 'center',
                        fontSize: 20,
                        display:'flex',
                        alignItems:'center'
                    }}>
                        <>
                        {icon && typeof icon === "string" ? <i className={icon}/> : icon}
                        {img && <IconPopup img={img}/>}
                        </>
                    </ListItemAvatar>
                    <ListItemText disableTypography={true} style={{fontSize: '1.1rem', marginTop:0 }}>{name}</ListItemText>
                </ListItem>
                }
            </React.Fragment>
        );
    }
}

ItemLink.propTypes = {
    style: PropTypes.any,
    className: PropTypes.string,
    icon: PropTypes.any,
    name: PropTypes.string.isRequired,

    onClick: PropTypes.func,
};

export default connect(state => ({
        themes: state.main.themes,
    }),
    (dispatch) => ({
        mainActions: bindActionCreators(mainActions, dispatch)
    })
)(Profile);
