/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 11/12/2019
 * @Example 
 */

import React, {PureComponent} from 'react';
import PropTypes              from "prop-types";
import Config                 from "../../config";
import Typography             from "@material-ui/core/Typography";
import IconButton             from "@material-ui/core/IconButton";
import {Column}               from "devextreme-react/data-grid";
import {FormGroup}            from "react-bootstrap";
import ButtonGeneral          from "../common/button/button-general";
import Modal                  from "../common/modal/modal";
import * as _                 from "lodash";
import TextField              from "../common/form-material/textfield";
import withStyles             from "@material-ui/core/styles/withStyles";
import SearchIcon             from '@material-ui/icons/Search';
import GridContainer          from "../grid-container/grid-container";

const styles = {
    paper: {
        alignSelf: 'start',
        overflowX: 'hidden'
    },
    searchTitle: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
        color: '#222B45',
        fontSize: '12px',
        marginTop: 17
    }
};

class PopupSelect2 extends PureComponent {
    constructor(props){
        super(props);
        this.state = {
            isOpen: props && props.open,
            selectedRowKeys: props && props.selectedRowKeys ? props.selectedRowKeys : []
        };
        this.filter = {
            limit: 10,
            skip: 0,
            StrSearch: ""
        };
        this.tmpSelectedRowKeys = props && props.selectedRowKeys ? props.selectedRowKeys : [];
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.open !== this.props.open) {
            this.setState({
                isOpen: this.props.open
            });
        }
        if (JSON.stringify(prevProps.selectedRowKeys) !== JSON.stringify(this.props.selectedRowKeys)) {
            this.tmpSelectedRowKeys = this.props.selectedRowKeys;
        }
        if (JSON.stringify(prevProps.dataSource) !== JSON.stringify(this.props.dataSource)) {
            this.setState({selectedRowKeys: this.tmpSelectedRowKeys});
        }
    }

    open = (data) => {
        const {multiple, onOpen} = this.props;
        if (data) {
            data = !Array.isArray(data) ? [data] : data;
            data = !multiple ? [data[0]] : data;
        }
        this.tmpSelectedRowKeys = data;
        this.setState({
            isOpen: true,
        }, () => {
            if (onOpen) onOpen();
        });
    };

    _onEntered = () => {
        setTimeout(() => {
            this.setState({
                selectedRowKeys: this.tmpSelectedRowKeys
            });
        }, 300);
    };

    onClose = (e) => {
        const {onClose} = this.props;
        this.setState({
            isOpen: false
        }, () => {
            if (onClose) onClose(e);
        });
    };

    onSearch = () => {
        const {onSearch} = this.props;
        if (onSearch) onSearch({strSearch: this.filter.StrSearch});
    };

    onChosen = () => {
        const {onChosen} = this.props;
        if (onChosen) onChosen(this.tmpSelectedRowKeys);
        this.onClose();
    };

    _selectionChanged = (e) => {
        const currentSelectedRowKeys = e.currentSelectedRowKeys;
        const currentDeselectedRowKeys = e.currentDeselectedRowKeys;
        const {dataSource} = this.props;
        if (currentDeselectedRowKeys.length === 1) {
            this.tmpSelectedRowKeys = this.tmpSelectedRowKeys.filter((e) => {
                return currentDeselectedRowKeys.indexOf(e) < 0;
            });
        } else if (currentDeselectedRowKeys.length === dataSource.length) {
            this.tmpSelectedRowKeys = [];
        } else if (currentSelectedRowKeys.length > 0) {
            currentSelectedRowKeys.forEach((val) => {
                if (this.tmpSelectedRowKeys.indexOf(val) < 0) {
                    this.tmpSelectedRowKeys.push(val);
                }
            });
        }
    };

    onSelectionChanged = (e) => {
        const {multiple} = this.props;
        if (!multiple) {
            if (e.currentSelectedRowKeys.length > 0 ) {
                this.tmpSelectedRowKeys = e.currentSelectedRowKeys;
                this.setState({
                    selectedRowKeys: e.currentSelectedRowKeys
                });
            }
        } else {
            this._selectionChanged(e);
            this.setState({
                selectedRowKeys: e.selectedRowKeys
            });
        }
    };

    onChangePage = (page) => {
        const {onLoad} = this.props;
        this.filter.skip = page * this.filter.limit;
        if (onLoad) onLoad(this.filter);
    };

    onChangePerPage = (per) => {
        const {onLoad} = this.props;
        this.filter.skip = 0;
        this.filter.limit = per;
        if (onLoad) onLoad(this.filter);
    };

    render() {
        const {classes, dataSource, column, itemPerPage, skipPerPage, title, className, style, fullWidth, maxWidth,
            keyExpr, allowSearch, searchPlaceholder, listPerPage, totalItems, typePaging, multiple, gridProps
        } = this.props;
        const {isOpen, selectedRowKeys} = this.state;
        const _itemPerPage = itemPerPage || itemPerPage === 0 ? itemPerPage : this.filter.limit;
        const _skipPerPage = skipPerPage || skipPerPage === 0 ? skipPerPage : this.filter.skip;
        console.log('test2', selectedRowKeys);

        return (
            <React.Fragment>
                <Modal
                    open={isOpen}
                    maxWidth={maxWidth}
                    fullWidth={fullWidth}
                    className={className}
                    style={style}
                >
                    <Modal.Title disableTypography>
                        <div className={"display_row align-center align-between"} style={{ width: "100%", margin: '4px 0', flexWrap: 'wrap' }}>
                            <div className={"display_row align-center"}>
                                <Typography variant="h6" className={"mgr10 text-uppercase"}>{title ? title : Config.lang("DHR_Chon")}</Typography>
                            </div>
                            <div className={'display_row'} {...(window.innerWidth < 576 ? { style: { width: '100%', justifyContent: 'flex-end' } } : {})}>
                                <ButtonGeneral name={Config.lang('DHR_Dong1')}
                                               size={"large"}
                                               typeButton={'cancel'}
                                               disabled={false}
                                               style={{ textTransform: 'uppercase', marginRight: 15 }}
                                               onClick={this.onClose} />
                                <ButtonGeneral name={Config.lang('DHR_Chon')}
                                               color={"info"}
                                               size={"large"}
                                               variant={"contained"}
                                               loading={false}
                                               disabled={_.isEmpty(selectedRowKeys)}
                                               style={{ textTransform: 'uppercase' }}
                                               onClick={this.onChosen} />
                            </div>
                        </div>
                    </Modal.Title>
                    <Modal.Content style={{height: "100%"}}>
                        {allowSearch && <div>
                            <span className={classes.searchTitle}>{Config.lang("DHR_Tim_kiem")}</span>
                            <FormGroup>
                                <TextField
                                    placeholder={searchPlaceholder ? searchPlaceholder : Config.lang("DHR_Nhap_de_tim_kiem")}
                                    variant={"outlined"}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    inputProps={{
                                        onKeyDown: (e) => {
                                            if (e && e.keyCode === 13) {
                                                this.onSearch();
                                            }
                                        }
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                                          <IconButton onClick={this.onSearch}>
                                                              <SearchIcon/>
                                                          </IconButton>
                                                      ),
                                    }}
                                    onChange={(e) => this.filter.StrSearch = e.target.value}
                                    fullWidth
                                />
                            </FormGroup>
                        </div>}
                        <FormGroup>
                            <GridContainer
                                totalItems={totalItems}
                                itemPerPage={_itemPerPage}
                                skipPerPage={_skipPerPage}
                                dataSource={dataSource}
                                // loading={modalLoaded}
                                keyExpr={keyExpr}
                                typePaging={typePaging}
                                gridProps={{
                                    style: { maxHeight: 410 }
                                }}
                                pagerFullScreen={false}
                                showBorders={false}
                                columnAutoWidth={true}
                                typeShort={window.innerWidth < 768}
                                height={"calc(100vh - 180px)"}
                                selection={{
                                    allowSelectAll: !!multiple,
                                    mode: 'multiple',
                                    selectAllMode: multiple ? "allPages" : '',
                                    showCheckBoxesMode: "always"
                                }}
                                allowColumnResizing={true}
                                listPerPage={listPerPage}
                                selectedRowKey={selectedRowKeys}
                                onChangePage={this.onChangePage}
                                onChangePerPage={this.onChangePerPage}
                                onSelectionChanged={this.onSelectionChanged}
                                {...gridProps}
                            >
                                {column.map((e, idx) => {
                                    return (
                                        <Column
                                            key={idx}
                                            caption={e.caption}
                                            dataField={e.dataField}
                                            visible={typeof e.visible === "undefined" ? true : e.visible}
                                            width={200}
                                        />
                                    );
                                })}
                            </GridContainer>
                        </FormGroup>
                    </Modal.Content>
                </Modal>
            </React.Fragment>
        );
    }
}

PopupSelect2.defaultProps = {
    allowSearch: true,
    typePaging: "remote"
};

PopupSelect2.propTypes = {
    dataSource: PropTypes.any,
    column: PropTypes.any,
    keyExpr: PropTypes.string,
    open: PropTypes.bool,
    value: PropTypes.any,
    itemPerPage: PropTypes.number,
    multiple: PropTypes.bool, //'single', 'multiple'
    className: PropTypes.string,
    style: PropTypes.string,
    title: PropTypes.string,
    fullWidth: PropTypes.bool,
    maxWidth: PropTypes.string,
    allowSearch: PropTypes.bool,
    searchPlaceholder: PropTypes.string,
    typePaging: PropTypes.string,

    onSearch: PropTypes.func,
    onLoad: PropTypes.func,
    onClose: PropTypes.func,
    onChosen: PropTypes.func,
};

export default withStyles(styles)(PopupSelect2);
