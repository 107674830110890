import React, {useEffect, useRef, useState} from "react";
import {
    Typography, TextField, Box, Grid,
    Paper, Table, TableHead, TableRow, TableCell, TableBody, Radio,
    Divider
} from "@material-ui/core";
import { useStyles } from "./styles";
import moment from "moment";
import {Col, FormGroup, Row} from "react-bootstrap";
import DoneAllIcon from "@material-ui/core/SvgIcon/SvgIcon";
import ButtonCustom from "../common/button/button-custom";
import {bindActionCreators, compose} from "redux";
import {connect} from "react-redux";
import * as reportActions from "../../redux/reports/report_actions";
import Config from "../../config";
import Attachments from "../attachments/attachments";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";

const getDate = (date) => {
    if (!date) return moment().format("YYYY-MM-DD");
    return moment(date).format("YYYY-MM-DD");
};

const checkAccess = (param, callBackDisabled) => {
    if (!param || !param.VoucherID || !param.DivisionID) {
        Config.popup.show('INFO', Config.lang("DHR_Thong_tin_cung_cap_khong_hop_le"));
        if (callBackDisabled) callBackDisabled(true);
        return false;
    }
    return true;
};

const FormReport = (props) => {
    const classes = useStyles();
    const attRef = useRef();
    const [listFiles, setListFiles] = useState([]);
    const [isSave, setIsSave] = useState(false);
    const [isUploading, setIsUploading] = useState(false);

    const [listDetail, setListDetail] = useState({});
    const [listDetailNote, setListDetailNote] = useState({});
    const [objectName, setObjectName] = useState("");
    const [contactPersonName, setContactPersonName] = useState("");
    const [position, setPosition] = useState("");
    const [respondedDate, setRespondedDate] = useState(moment());
    const [respondedNotes, setRespondedNotes] = useState("");
    const [totalPoint, setTotalPoint] = useState(0);

    const [detail, setDetail] = useState([]);

    const [companyName, setCompanyName] = useState("");
    const [companyAddress, setCompanyAddress] = useState("");
    const [companyPhone, setCompanyPhone] = useState("");
    const [companyFax, setCompanyFax] = useState("");

    const textConven = [
        Config.lang("DHR_Chua_dap_ung"),
        Config.lang("DHR_Tam_dap_ung_Can_cai_tien_them"),
        Config.lang("DHR_Dap_ung"),
        Config.lang("DHR_Hai_long_can_cai_tien_them"),
        Config.lang("DHR_Hoan_toan_hai_long")
    ];

    const loadDataForm = () => {
        const {query} = props.location;
        if (!checkAccess(props.location.query, setIsSave)) return false;
        const param = {
            VoucherID: query.VoucherID ? query.VoucherID : "",
            DivisionID: query.DivisionID ? query.DivisionID : ""
        };

        props.reportActions.getFormInfo(param, (error, data) => {
            if (error) {
                let errorCode = error.code || null;
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                switch (errorCode) {
                    case "F2500E001":
                        message = Config.lang("DHR_VoucherID_khong_duoc_de_trong");
                        break;
                    default:
                        message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                        break;
                }
                Config.popup.show('INFO', message);
                return false;
            }
            if (data) {
                if (data.Status && data.Status === 1) {
                    let message = data.Message || Config.lang("DHR_Loi_chua_xac_dinh");
                    Config.popup.show('INFO', message);
                    setIsSave(true);
                    return false;
                } else {
                    setDataForm(data);
                }
            }
        });
    };

    useEffect(() => {
        let total = 0;
        Object.keys(listDetail).forEach((e) => {
            total += Number(listDetail[e]);
        });
        setTotalPoint((total / Object.keys(listDetail).length).toFixed(2));
    }, [listDetail]);

    useEffect(() => {
        /* eslint-disable */
        loadDataForm();
    }, []);

    const setDataForm = (data) => {
        const {master, detail, footer} = data;
        if (master) {
            if (master.ObjectName) setObjectName(master.ObjectName);
            if (master.ContactPersonName) setContactPersonName(master.ContactPersonName);
            if (master.Position) setPosition(master.Position);
            if (master.RespondedDate) setRespondedDate(getDate(master.RespondedDate));
            if (master.RespondedNotes) setRespondedNotes(master.RespondedNotes);
            if (master.TotalPoint) setTotalPoint(master.TotalPoint);
        }
        if (detail) {
            let _listDetail = {};
            let _listDetailNote = {};
            detail.forEach((e) => {
                _listDetail[e.CriteriaID] = e.Point;
                _listDetailNote[e.CriteriaID] = e.DetailRespondedNotes;
            });
            setListDetail(_listDetail);
            setListDetailNote(_listDetailNote);
            setDetail(detail);
        }
        if (footer) {
            if (footer.CompanyName) setCompanyName(footer.CompanyName);
            if (footer.CompanyAddress) setCompanyAddress(footer.CompanyAddress);
            if (footer.CompanyPhone) setCompanyPhone(footer.CompanyPhone);
            if (footer.CompanyFax) setCompanyFax(footer.CompanyFax);
        }
    };


    const handleTextChange = (e, field) => {
        switch (field) {
            case "ContactPersonName":
                setContactPersonName(e.target.value);
                break;
            case "Position":
                setPosition(e.target.value);
                break;
            case "RespondedNotes":
                setRespondedNotes(e.target.value);
                break;
            case "TotalPoint":
                setRespondedNotes(e.target.value);
                break;
            default:
                break;
        }
    };

    const handleDateChange = (date) => {
        setRespondedDate(date);
    };

    const handleRadioChange = (e, criteriaID) => {
        setListDetail({
            ...listDetail,
            [criteriaID]: Number(e.target.value)
        });
    };

    const handleDetailNoteChange = (e, criteriaID) => {
        setListDetailNote({
            ...listDetailNote,
            [criteriaID]: e.target.value
    });
    };

    const onAttachment = () => {
        attRef.current.onAttachment();
    };

    const onUploading = (e) => {
        setIsUploading(e.uploading);
    };

    const onChangeUpload = (e) => {
        setListFiles(e.files);
    };

    const _saveCDN = async () => {
        const uploadFiles = await attRef.current._uploadFile(listFiles, 'multiple');
        //temp..
        if (uploadFiles.code === 200 && uploadFiles.data) {
            const data = uploadFiles.data.paths;

            let listAttachments = [];
            data.forEach((att, i) => {
                listAttachments.push({
                    name: att.fileName,
                    size: att.fileSize,
                    ext: "." + Config.helpers.getExtFile(att.fileName),
                    url: att.url
                });
            });
            return listAttachments;
        } else {
            return [];
        }
    };

    const _saveAttachments = async (afterSaveAttachment) => {
        const {query} = props.location;
        const listAttachments = await _saveCDN();

        let arrAttachment = [];
        listAttachments.forEach((att) => {
            arrAttachment.push({
                URL: att.url ? att.url : "",
                FileName: att.name ? att.name : "",
                FileSize: att.size ? att.size : "",
                FileExt: att.ext ? att.ext : ""
            });
        });
        let param = {
            params: JSON.stringify({
                TableName: "D17T2500_WEB",
                KeyID: query.VoucherID ? query.VoucherID : "",
                DivisionID: query.DivisionID ? query.DivisionID : "",
                arrAttachment: arrAttachment,
            })
        };

        props.reportActions.saveAttachment(param, (error) => {
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                setIsSave(false);
                return false;
            }

            if (afterSaveAttachment) afterSaveAttachment();
        });
    };

    const _saveData = () => {
        let _listDetail = [];
        detail.forEach((d) => {
            const tmp = {
                CriteriaID: d.CriteriaID,
                Point: listDetail[d.CriteriaID],
                DetailRespondedNotes: listDetailNote[d.CriteriaID]
            };
            _listDetail.push(tmp);
        });
        const {query} = props.location;
        let paramSave = {
            data: JSON.stringify({
                VoucherID: query.VoucherID ? query.VoucherID : "",
                DivisionID: query.DivisionID ? query.DivisionID : "",
                master: {
                    ContactPersonName: contactPersonName,
                    Position: position,
                    RespondedDate: respondedDate ? moment(respondedDate).format("YYYY-MM-DD") : "",
                    TotalPoint: totalPoint ? totalPoint : 0,
                    RespondedNotes: respondedNotes
                },
                detail: _listDetail
            })
        };

        props.reportActions.saveVouchers(paramSave, (error) => {
            if (error) {
                let errorCode = error.code || null;
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                switch (errorCode) {
                    case "F2500E010":
                        message = Config.lang("DHR_Cac_tham_so_la_bat_buoc");
                        break;
                    case "F2500E009":
                        message = Config.lang("DHR_Tham_so_truyen_vao_khong_hop_le");
                        break;
                    case "F2500E001":
                        message = Config.lang("DHR_VoucherID_khong_duoc_de_trong");
                        break;
                    case "F2500E002":
                        message = Config.lang("DHR_Khoi_la_bat_buoc_nhap");
                        break;
                    case "F2500E011":
                        message = Config.lang("DHR_Cac_tieu_chi_la_bat_buoc_nhap");
                        break;
                    case "F2500E008":
                        message = Config.lang("DHR_Luu_thong_tin_chung_tu_khong_thanh_cong");
                        break;
                    default:
                        message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                        break;
                }
                Config.popup.show('INFO', message);
                setIsSave(false);
                return false;
            }

            setIsSave(true);
            Config.notify.show('success', Config.lang("DHR_Luu_thanh_cong"), 2000);
        });
    };

    const onSave = () => {
        if (!validateForm()) return false;
        setIsSave(true);
        if (listFiles && listFiles.length > 0) {
            _saveAttachments(_saveData);
        } else {
            _saveData();
        }
    };

    const validateForm = () => {
        if (isUploading) {
            Config.popup.show('INFO', Config.lang("DHR_Tap_tin_dinh_kem_dang_duoc_xu_ly"));
            return false;
        }
        if (!contactPersonName) {
            Config.popup.show('INFO', Config.lang("DHR_Nguoi_danh_gia_bat_buoc_nhap"));
            return false;
        }
        if (!position) {
            Config.popup.show('INFO', Config.lang("DHR_Chuc_vu_bat_buoc_nhap"));
            return false;
        }
        if (!respondedDate) {
            Config.popup.show('INFO', Config.lang("DHR_Ngay_danh_gia_bat_buoc_nhap"));
            return false;
        }
        return true;
    };

  return (
    <React.Fragment>
        <FormGroup>
            <Typography
                className={classes.titleContent}
                gutterBottom
                align="left"
                variant="h5"
            >
                {Config.lang("DHR_Thong_tin_chinh")}
            </Typography>
            <form>
                <div className={classes.wrapperDiv}>
                    <TextField
                        id="standard-full-width"
                        label={Config.lang("DHR_Cong_trinh/Cong_ty")}
                        style={{margin: 8}}
                        value={objectName}
                        fullWidth
                        disabled={true}
                        margin="normal"
                        InputLabelProps={{
                            shrink: true
                        }}
                    />{" "}
                    <TextField
                        id="standard-full-width"
                        label={Config.lang("DHR_Nguoi_danh_gia")}
                        style={{margin: 8}}
                        value={contactPersonName}
                        fullWidth
                        disabled={isSave}
                        margin="normal"
                        InputLabelProps={{
                            shrink: true
                        }}
                        onChange={(e) => handleTextChange(e, "ContactPersonName")}
                    />{" "}
                    <TextField
                        id="standard-full-width"
                        label={Config.lang("DHR_Chuc_vu")}
                        style={{margin: 8}}
                        value={position}
                        fullWidth
                        disabled={isSave}
                        margin="normal"
                        InputLabelProps={{
                            shrink: true
                        }}
                        onChange={(e) => handleTextChange(e, "Position")}
                    />
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            style={{margin: 8}}
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="date-picker-inline"
                            label={Config.lang("DHR_Ngay_danh_gia")}
                            disabled={isSave}
                            TextFieldComponent={TextField}
                            value={respondedDate ? respondedDate : moment()}
                            onChange={handleDateChange}
                            fullWidth={true}
                        />
                    </MuiPickersUtilsProvider>
                </div>
            </form>
        </FormGroup>
        <FormGroup>
            <Typography
                className={classes.titleContent}
                gutterBottom
                align="left"
                variant="h5"
            >
                Nội dung khảo sát
            </Typography>
            <div className={classes.wrapperDiv}>
                <Box className={classes.boxSub}>
                    <DoneAllIcon fontSize={'small'} className={classes.iconSubTitle}/>
                    <Typography className={classes.subTitle} variant="h6">
                        {Config.lang("DHR_AC_vui_long_chon_y_kien_cua_minh")}
                    </Typography>
                </Box>
                <Box className={classes.boxSub}>
                    <Typography className={classes.subTitle} gutterBottom variant="caption">
                        {Config.lang("DHR_Quy_uoc_nhu_sau")}:
                    </Typography>
                </Box>
                <Grid container justify="center">
                    {textConven && textConven.map((e, i) => {
                        return (
                            <label key={i} className={"text-bold"} style={{marginRight: 25}}>{(i+1) + ". " + e}</label>
                        );
                    })}
                </Grid>
                <FormGroup>
                    <Paper className={"table-responsive"} style={{maxHeight: 620, overflowY: "auto"}}>
                        <div>
                            <Table size="small" style={{minWidth: 768}}>
                                <TableHead style={{backgroundColor: '#C9F3FF'}}>
                                    <TableRow>
                                        <TableCell style={{width: 30}}rowSpan={2}>STT</TableCell>
                                        <TableCell size={"small"} align="center" rowSpan={2}>{Config.lang("DHR_Noi_dung")}</TableCell>
                                        <TableCell size={"small"} align="center" colSpan={5} style={{width: 100}}>{Config.lang("DHR_Muc_do_dong_y")}</TableCell>
                                        <TableCell size={"small"} align="center" rowSpan={3}>{Config.lang("DHR_Y_kien_dong_gop")}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell padding={'checkbox'} align="center" style={{width: 30}}>1</TableCell>
                                        <TableCell padding={'checkbox'} align="center" style={{width: 30}}>2</TableCell>
                                        <TableCell padding={'checkbox'} align="center" style={{width: 30}}>3</TableCell>
                                        <TableCell padding={'checkbox'} align="center" style={{width: 30}}>4</TableCell>
                                        <TableCell padding={'checkbox'} align="center" style={{width: 30}}>5</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {detail && detail.map((row, i) => {
                                        return (
                                            <TableRow key={i}>
                                                <TableCell align="center">{row.OrderNum}</TableCell>
                                                <TableCell align="center">{row.Description}</TableCell>
                                                <TableCell padding={'checkbox'} align="center">
                                                    <Radio
                                                        checked={listDetail[row.CriteriaID] === 1}
                                                        className={classes.radioButton}
                                                        value={1}
                                                        disabled={isSave}
                                                        name="radiobutton1"
                                                        onChange={(e) => handleRadioChange(e, row.CriteriaID)}
                                                    />
                                                </TableCell>
                                                <TableCell padding={'checkbox'} align="center">
                                                    <Radio
                                                        checked={listDetail[row.CriteriaID] === 2}
                                                        className={classes.radioButton}
                                                        value={2}
                                                        disabled={isSave}
                                                        onChange={(e) => handleRadioChange(e, row.CriteriaID)}
                                                    />
                                                </TableCell>
                                                <TableCell padding={'checkbox'} align="center">
                                                    <Radio
                                                        checked={listDetail[row.CriteriaID] === 3}
                                                        className={classes.radioButton}
                                                        value={3}
                                                        disabled={isSave}
                                                        onChange={(e) => handleRadioChange(e, row.CriteriaID)}
                                                    />
                                                </TableCell>
                                                <TableCell padding={'checkbox'} align="center">
                                                    <Radio
                                                        checked={listDetail[row.CriteriaID] === 4}
                                                        className={classes.radioButton}
                                                        value={4}
                                                        disabled={isSave}
                                                        onChange={(e) => handleRadioChange(e, row.CriteriaID)}
                                                    />
                                                </TableCell>
                                                <TableCell padding={'checkbox'} align="center">
                                                    <Radio
                                                        checked={listDetail[row.CriteriaID] === 5}
                                                        className={classes.radioButton}
                                                        value={5}
                                                        disabled={isSave}
                                                        onChange={(e) => handleRadioChange(e, row.CriteriaID)}
                                                    />
                                                </TableCell>
                                                <TableCell align="center">
                                                    <TextField
                                                        id="standard-full-width"
                                                        defaultValue={row.DetailRespondedNotes}
                                                        value={listDetailNote[row.CriteriaID]}
                                                        fullWidth
                                                        disabled={isSave}
                                                        margin={"dense"}
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        onChange={(e) => handleDetailNoteChange(e, row.CriteriaID)}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </div>
                    </Paper>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Typography className={classes.titleInput + " pull-left"} variant="caption">
                                {Config.lang("DHR_Tong_diem")}:
                            </Typography>
                            <TextField
                                className={"pull-left"}
                                margin={'dense'}
                                type={'number'}
                                variant={'standard'}
                                style={{width: 300}}
                                value={totalPoint}
                                disabled={true}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Typography className={classes.subTitle} variant="caption">
                                {Config.lang("DHR_Cac_gop_y_khac")}:
                            </Typography>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                multiline
                                rows={5}
                                margin={"normal"}
                                variant={"filled"}
                                fullWidth
                                disabled={isSave}
                                value={respondedNotes}
                                onChange={(e) => handleTextChange(e, "RespondedNotes")}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Box className={classes.boxSub}>
                        <Typography className={classes.subTitle} variant="h6" align={"center"}>
                            {Config.lang("DHR_Nhung_y_kien_nay_se_duoc_cong_ty_chung_toi_cong_nhan")}
                            <div className={'text-center'} style={{fontSize: 20}}>❧</div>
                        </Typography>
                    </Box>
                </FormGroup>
                <FormGroup>
                    <Attachments
                        ref={attRef}
                        uploading={isUploading}
                        showButton={false}
                        disabled={isSave}
                        chooseWithoutSave={true}
                        onUploading={onUploading}
                        onChange={onChangeUpload}
                    />
                </FormGroup>
                <FormGroup>
                    <ButtonCustom
                        className={"pull-right"}
                        color={"primary"}
                        size={'small'}
                        variant={'contained'}
                        name={Config.lang("DHR_Gui")}
                        disabled={isSave}
                        onClick={onSave}
                    />
                    <ButtonCustom
                        className={"pull-right mgr5"}
                        size={'small'}
                        name={Config.lang("DHR_Dinh_kem")}
                        disabled={isSave}
                        onClick={onAttachment}
                    />
                </FormGroup>
                <div className={classes.clsfx} />
            </div>
        </FormGroup>
        <FormGroup>
            <FormGroup>
                <fieldset>
                    <Divider />
                </fieldset>
            </FormGroup>
            <FormGroup>
                <Row>
                    <Col xs={12} sm={6} md={6} lg={6}>
                        <Typography className={classes.titleInput + " pull-left"} variant="caption">
                            {Config.lang("DHR_Cong_ty")}:
                        </Typography>
                        <TextField
                            className={"pull-left"}
                            margin={'dense'}
                            variant={'standard'}
                            style={{width: '75%'}}
                            value={companyName}
                            disabled={true}
                        />
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6}>
                        <Typography className={classes.titleInput + " pull-left"} variant="caption">
                            {Config.lang("DHR_So_Fax")}:
                        </Typography>
                        <TextField
                            className={"pull-left"}
                            margin={'dense'}
                            variant={'standard'}
                            style={{width: '75%'}}
                            value={companyFax}
                            disabled={true}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={6} md={6} lg={6}>
                        <Typography className={classes.titleInput + " pull-left"} variant="caption">
                            {Config.lang("DHR_Dia_chi")}:
                        </Typography>
                        <TextField
                            className={"pull-left"}
                            margin={'dense'}
                            variant={'standard'}
                            style={{width: '75%'}}
                            value={companyAddress}
                            disabled={true}
                        />
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6}>
                        <Typography className={classes.titleInput + " pull-left"} variant="caption">
                            {Config.lang("DHR_Dien_thoai")}:
                        </Typography>
                        <TextField
                            className={"pull-left"}
                            margin={'dense'}
                            variant={'standard'}
                            style={{width: '70%'}}
                            value={companyPhone}
                            disabled={true}
                        />
                    </Col>
                </Row>
            </FormGroup>
        </FormGroup>
    </React.Fragment>
  );
};

export default compose(connect(null,
    (dispatch) => ({
        reportActions: bindActionCreators(reportActions, dispatch),
    })
))(FormReport);